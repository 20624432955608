import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { joinChannel } from '@gtintel/platform-api';
import { GTIIcon, ProjectIcon } from '@gtintel/platform-atoms';
import { CLOSED_ID, SCOPING_ID, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import { UseChatContext } from '@gtintel/platform-context';
import { Client } from '@twilio/conversations';
import { Container } from './styles';
import { countUnreadMessages } from '../../../utils';
import ChatButton from './ChatButton';
const ProjectMembersChatButtons = ({
  onConnect,
  project,
  user
}) => {
  const twilio_client_admin = project.twilio_client_admin;
  const twilio_client_partner = project.twilio_client_partner;
  const twilio_partner_admin = project.twilio_partner_admin;
  const [twilioClient, setTwilioClient] = useState(null);
  const [conversationData, setConversationData] = useState({
    twilio_client_admin: {
      channelName: 'twilio_client_admin',
      channelId: '',
      channel: null,
      isInitializationFailed: false,
      isInitializing: false,
      unreadMessageCount: 0
    },
    twilio_partner_admin: {
      channelName: 'twilio_partner_admin',
      channelId: '',
      channel: null,
      isInitializationFailed: false,
      isInitializing: false,
      unreadMessageCount: 0
    },
    twilio_client_partner: {
      channelName: 'twilio_client_partner',
      channelId: '',
      channel: null,
      isInitializationFailed: false,
      isInitializing: false,
      unreadMessageCount: 0
    }
  });
  const {
    createChatDetailRegister,
    verifyIfChatIsOpen
  } = UseChatContext();
  const setChannelData = (channelName, key, value) => {
    setConversationData(cd => {
      const channelState = {
        ...cd[channelName]
      };
      channelState[key] = value;
      return {
        ...cd,
        [channelName]: channelState
      };
    });
  };

  // setChannelData can't be used to increment UMC because when you increment,
  // the new state depends on the previous state, which prevents setChannelData
  // from working due to the way React state updates work.
  const incrementUnreadMessageCount = channelName => {
    setConversationData(cd => {
      const channelState = {
        ...cd[channelName]
      };
      channelState.unreadMessageCount++;
      return {
        ...cd,
        [channelName]: channelState
      };
    });
  };
  useEffect(() => {
    const token = Cookies.get('tw');
    if (token) {
      const client = new Client(token);
      client.on('connectionStateChanged', state => {
        if (state === 'connected') {
          setTwilioClient(client);
        }
      });
    }
  }, []);
  const handleMessageAdded = (message, channelId, channelName) => {
    if (verifyIfChatIsOpen(channelId)) {
      setChannelData(channelName, 'unreadMessageCount', 0);
    }
    const isAuthorAdmin = message.author === TWILIO_ADMIN_IDENTITY;
    const isAuthorCurrentUser = message.author === String(user.id);
    if (!(isAuthorAdmin && user.is_administrator) && !isAuthorCurrentUser) {
      incrementUnreadMessageCount(channelName);
    }
  };
  useEffect(() => {
    if (!twilioClient) return;
    [[twilio_client_admin, 'twilio_client_admin'], [twilio_client_partner, 'twilio_client_partner']].forEach(async ([channelId, channelName]) => {
      let conversation;
      try {
        // This prints a warning if you're not a member of the twilio
        // conversation; it suggests using `peekConversationBySid instead - but
        // that method isn't documented. Twilio customer support told me (Nov
        // 2022) that this is a mistake & should be fixed in a future SDK
        // version (we're on 2.2.0 at time of writing.)
        //
        // Unfortunately, the reason we're getting the conversation is to
        // figure out how many messages are unread, which means we need to know
        // the convo's lastReadMessageIndex - and peekConversationBySid doesn't
        // return this info (it's always null.) So I'm not sure what we can do
        // except use getConversationBySid (which incidentally is also much
        // slower than peek) and put up with the warning.
        //
        // If a future Twilio SDK version upgrade removes the ability for
        // getConversationBySid to return conversations you're not a part of,
        // it will break this - and I'm not sure what other way there would be
        // to get the lastReadMessageIndex. But it works for now.
        //
        // (NB we can't simply use conversation.getUnreadMessagesCount()
        // to get the count because that method also only works if you're
        // a member of the conversation)
        conversation = await twilioClient.getConversationBySid(channelId);
      } catch (e) {
        // noop
        return;
      }
      setChannelData(channelName, 'channel', conversation);
      conversation.on('messageAdded', message => handleMessageAdded(message, channelId, channelName));
      const unreadMessageCount = countUnreadMessages(conversation);
      setChannelData(channelName, 'unreadMessageCount', unreadMessageCount);
    });
    return () => {
      Object.keys(conversationData).forEach(item => {
        conversationData[item]?.channel?.removeAllListeners();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioClient]);
  const clientPartnerChatName = user.is_network_partner ? 'Chat with Client Team' : user.is_client ? 'Chat with Partner' : 'Project Chat';
  const partnerAdminChatName = user.is_administrator ? 'NP - GTI' : 'Chat with GTI';
  const clientAdminChatName = user.is_administrator ? 'Client - GTI' : 'Chat with GTI';
  const onClickChatButton = async ({
    channelName,
    channelSid,
    groupMembers,
    popupName,
    userId
  }) => {
    if (channelName !== 'twilio_partner_admin') {
      // 'initializing' = adding project's members to Twilio conversation. We
      // don't add them until now so they're not added to Twilio conversations
      // that they don't use; otherwise they get added to unnecessary chats,
      // hit Twilio's limit and get errors.,
      //
      // We make this API call on every click to ensure the addition of any new
      // project members who were added since the last click. If there are no
      // new members to add, the call should return quickly.
      //
      // Initialization only adds clients to the chat, not partners or admins,
      // so the partner_admin chat doesn't need 'initializing' like this.
      setChannelData(channelName, 'isInitializing', true);
      try {
        await joinChannel({
          channel_name: channelName,
          project_id: project.id
        });
        setChannelData(channelName, 'isInitializing', false);
      } catch (e) {
        setChannelData(channelName, 'isInitializing', false);
        setChannelData(channelName, 'isInitializationFailed', true);
        setTimeout(() => setChannelData(channelName, 'isInitializationFailed', false), 2000);
        throw e;
      }
    }
    createChatDetailRegister(channelSid);
    setChannelData(channelName, 'unreadMessageCount', 0);
    onConnect({
      channelSid,
      identity: userId,
      popupName,
      groupMembers
    });
  };
  return <Container>
      <ChatButton chatName={clientPartnerChatName} channelSid={twilio_client_partner} htmlId="project-members-chat-button" icon={<ProjectIcon />} isDisabled={!project.partner || project.status < SCOPING_ID || project.status === CLOSED_ID} isInitializationFailed={conversationData.twilio_client_partner.isInitializationFailed} isLoading={conversationData.twilio_client_partner.isInitializing} isUserAdmin={user.is_administrator} messageCount={conversationData.twilio_client_partner.unreadMessageCount} onClick={() => {
      const otherUser = user.is_network_partner ? project.client : project.partner;
      onClickChatButton({
        channelName: 'twilio_client_partner',
        channelSid: twilio_client_partner,
        groupMembers: [{
          ...project.client
        }, {
          ...project.partner
        }],
        popupName: clientPartnerChatName,
        userId: `project_${otherUser?.id}`
      });
    }} />

      {(user.is_administrator || user.is_network_partner) && <ChatButton chatName={partnerAdminChatName} channelSid={twilio_partner_admin} htmlId="np-gti-chat-button" icon={<GTIIcon />} isInitializationFailed={conversationData.twilio_partner_admin.isInitializationFailed} isLoading={conversationData.twilio_partner_admin.isInitializing} isUserAdmin={user.is_administrator} messageCount={conversationData.twilio_partner_admin.unreadMessageCount} onClick={() => {
      onClickChatButton({
        channelName: 'twilio_partner_admin',
        channelSid: twilio_partner_admin,
        popupName: partnerAdminChatName,
        userId: project.partner?.id ?? user.id
      });
    }} />}
      {(user.is_administrator || user.is_client) && <ChatButton chatName={clientAdminChatName} channelSid={twilio_client_admin} htmlId="client-gti-chat-button" icon={<GTIIcon />} isInitializationFailed={conversationData.twilio_client_admin.isInitializationFailed} isLoading={conversationData.twilio_client_admin.isInitializing} isUserAdmin={user.is_administrator} messageCount={conversationData.twilio_client_admin.unreadMessageCount} onClick={() => {
      onClickChatButton({
        channelName: 'twilio_client_admin',
        channelSid: twilio_client_admin,
        popupName: clientAdminChatName,
        userId: user.id
      });
    }} />}
    </Container>;
};
export default ProjectMembersChatButtons;