import React from 'react';
import { Client } from '@twilio/conversations';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { subMinutes } from 'date-fns';
import { getTwilioToken, addMemberToConversation, addAdminToChat, removeAdminFromChat, detailUserAction } from '@gtintel/platform-api';
import { ADMIN_AVATAR, COOKIE_NAMES, GTI_STAFF, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import { ChatContext } from '@gtintel/platform-context';
import { setCookie } from '@gtintel/platform-utils';
import PopUp from './Popup';
class ChatPopup extends React.Component {
  constructor(props) {
    super(props);
    this.isRefreshingToken = void 0;
    this.twilioClient = void 0;
    this.componentWillUnmount = () => {
      this.props.onRef(undefined);
      if (this.state.conversation) this.state.conversation.removeAllListeners();
      if (!this.state.adminStarChannel && this.state.myInfor?.is_administrator && this.state.conversation?.leave) {
        this.state.conversation.leave().then(() => removeAdminFromChat({
          identity: TWILIO_ADMIN_IDENTITY,
          channel: this.props.channel_sid ?? String(Number(this.state.myInfor?.id) * Number(this.state.receiver.identity))
        })).catch(() => {});
      }
    };
    this.createChatChannel = async () => {
      const user = Cookies.getJSON(COOKIE_NAMES.INFOR_USER);
      if (user) {
        user.full_name = `${user?.first_name} ${user?.last_name}`;
      }
      if (user?.is_administrator) {
        user.id = TWILIO_ADMIN_IDENTITY;
        user.full_name = GTI_STAFF;
        await addAdminToChat({
          identity: TWILIO_ADMIN_IDENTITY,
          channel: this.props.channel_sid ?? String(Number(user?.id) * Number(this.state.receiver.identity))
        }).catch(() => {});
      }
      if (this.props.isDM) {
        this.createPersonChat(user);
      } else {
        this.createProjectChat(user);
      }
    };
    this.handleFavoriteChannel = isStarred => {
      const {
        channel_sid
      } = this.props;
      this.twilioClient.peekConversationBySid(channel_sid ?? this.state.conversation.sid).then(conversation => {
        const attrs = conversation.attributes;
        attrs.adminStarChannel = isStarred;
        conversation.updateAttributes(attrs);
      });
      this.setState({
        adminStarChannel: isStarred
      });
    };
    this.state = {
      // sid of currently open popup; null if all are closed/minimized:
      activeSid: null,
      charIcon: null,
      conversation: null,
      receiver: {
        id: null,
        avatar: null,
        first_name: null,
        last_name: null,
        // full_name: null,
        is_administrator: null,
        is_client: null,
        is_network_partner: null,
        // username: null,
        identity: null
        // charIcon: null,
      },

      myInfor: {
        id: null,
        avatar: null,
        first_name: null,
        is_administrator: null,
        is_client: null,
        is_network_partner: null,
        last_name: null,
        // username: null,
        identity: null
      },
      adminStarChannel: false,
      popupOpen: this.props.hideChat ?? false,
      hideChat: this.props.hideChat ?? false
    };
  }
  componentDidMount() {
    const user = Cookies.getJSON(COOKIE_NAMES.INFOR_USER);
    if (user) {
      user.identity = user.id;
      if (user.is_administrator) {
        user.identity = TWILIO_ADMIN_IDENTITY;
      }
    }
    this.setTwilioToken(user);
    this.setReceiver();
    this.setState({
      myInfor: {
        ...user
      }
    });
    this.props.onRef({
      ...this,
      openChat: this.openChat.bind(this),
      toggleChat: this.toggleChat.bind(this),
      closeChat: this.closeChat.bind(this)
    });
    this.isRefreshingToken = false;
  }
  async openChat(channelSid) {
    this.toggleChat(channelSid);
    this.setState({
      popupOpen: true
    });
  }
  async closeChat() {
    this.context.changeChatStatus(this.state.hideChat, this.props.channel_sid);
    this.setState({
      hideChat: !this.state.hideChat
    });
  }
  async toggleChat(channelSid, loca = 0) {
    const {
      getLoca
    } = this.props;
    const {
      activeSid
    } = this.state;
    this.setState({
      activeSid: activeSid || channelSid === activeSid ? null : channelSid,
      popupOpen: true
    });
    this.state.conversation?.setAllMessagesRead();
    if (getLoca) {
      getLoca(loca);
    }
  }
  async getUserDetails(receiverId, isAnonymous) {
    return detailUserAction(receiverId).then(res => {
      return {
        ...res,
        identity: res.id,
        full_name: res.is_client && isAnonymous ? 'Anonymous Client' : `${res.first_name} ${res.last_name}`,
        charIcon: res.is_client ? 'CL' : 'NP'
      };
    });
  }
  async setReceiver() {
    if (String(this.props.receiver_id) === TWILIO_ADMIN_IDENTITY) {
      const receiver = {
        id: null,
        avatar: ADMIN_AVATAR,
        first_name: GTI_STAFF,
        full_name: GTI_STAFF,
        identity: TWILIO_ADMIN_IDENTITY,
        is_administrator: true,
        is_client: false,
        is_network_partner: false,
        last_name: ''
      };
      this.setState({
        receiver,
        charIcon: 'GT'
      });
    } else if (!this.props.groupMembers) {
      const receiver = await this.getUserDetails(this.props.receiver_id, this.props.projectInfo.request_anonymity);
      // @ts-ignore
      this.setState({
        receiver,
        charIcon: receiver.charIcon
      });
    } else if (this.props.groupMembers.length) {
      this.setState({
        charIcon: 'NA'
      });
    }
  }
  setTwilioToken(user = null) {
    const twilioToken = Cookies.get(COOKIE_NAMES.TWILIO_USER_TOKEN);
    if (twilioToken && twilioToken !== 'undefined') {
      const tokenDecode = jwtDecode(twilioToken);
      if (tokenDecode.grants.identity === user?.identity && new Date() < subMinutes(new Date(tokenDecode.exp * 1000), 4)) {
        this.getChatClient(twilioToken);
      } else {
        this.refreshToken();
      }
    } else {
      this.refreshToken();
    }
  }
  refreshToken() {
    if (this.isRefreshingToken) return;
    this.isRefreshingToken = true;
    return getTwilioToken().then(data => {
      this.isRefreshingToken = false;
      setCookie(COOKIE_NAMES.TWILIO_USER_TOKEN, data.token);
      this.getChatClient(data.token);
    });
  }
  async getChatClient(twilioToken) {
    this.twilioClient = new Client(twilioToken);
    this.twilioClient.on('tokenAboutToExpire', async () => {
      if (this.isRefreshingToken) return;
      this.isRefreshingToken = true;
      const token = await getTwilioToken();
      this.isRefreshingToken = false;
      this.twilioClient.updateToken(token).catch(() => {});
    }).on('connectionStateChanged', async state => {
      if (state === 'connected') {
        this.createChatChannel();
      } else if (state === 'denied') {
        Cookies.remove(COOKIE_NAMES.TWILIO_USER_TOKEN);
        this.setTwilioToken();
      }
    });
  }
  async createProjectChat(user) {
    try {
      const conversation = await this.twilioClient.peekConversationBySid(this.props.channel_sid);
      conversation.join().then(() => {}).catch(() => {});
      const addMemberToConversationParams = {
        attributes: {
          member: []
        },
        conversation_sid: this.props.channel_sid,
        identity: null
      };

      // `user` and `this.state.receiver` are unknown chat paricipants because this is a generic re-usable component.

      // If this is the NP <> Admin chat, only add those users to the chat.
      //  `user` and `this.state.receiver` are the NP and GTI Admin, but we don't know which is which
      //  because this is a generic re-usable component. So we add both to the chat.
      if (this.props.channel_sid === this.props.projectInfo?.twilio_partner_admin) {
        addMemberToConversationParams.identity = this.state.receiver.identity;
        addMemberToConversationParams.attributes = {
          member: [{
            user_id: user?.id,
            name: user?.full_name,
            avatar: user?.avatar
          }, {
            user_id: this.state.receiver.identity,
            name: this.state.receiver.full_name,
            avatar: this.state.receiver.avatar
          }]
        };
      }

      // If this is the NP <> Client chat
      else if (this.props.channel_sid === this.props.projectInfo?.twilio_client_partner) {
        // Add all project members to conversation attributes. If `user` is the admin user, then we'll
        //  force shouldAnonymise to false so that admins can see which client user is talking.
        addMemberToConversationParams.identity = this.props.projectInfo.partner.id;
        addMemberToConversationParams.attributes = {
          member: this.props.projectInfo?.members.map(member => {
            const shouldAnonymise = (!user.is_administrator && this.props.projectInfo.request_anonymity) ?? true;
            return {
              user_id: member.id,
              name: shouldAnonymise ? 'Anonymous Client' : `${member.first_name} ${member.last_name}`,
              avatar: shouldAnonymise ? '' : member.avatar
            };
          })
        };
        addMemberToConversationParams.attributes.member.push({
          user_id: this.props.projectInfo.partner.id,
          name: `${this.props.projectInfo.partner.first_name} ${this.props.projectInfo.partner.last_name}`,
          avatar: this.props.projectInfo.partner.avatar,
          is_partner: true
        });
      }

      // If this is the Client <> Admin chat
      else if (this.props.channel_sid === this.props.projectInfo?.twilio_client_admin) {
        // Add all project members to conversation attributes
        addMemberToConversationParams.identity = this.props.projectInfo.client.id;
        addMemberToConversationParams.attributes = {
          member: this.props.projectInfo?.members.map(member => {
            return {
              user_id: member.id,
              name: `${member.first_name} ${member.last_name}`,
              avatar: member.avatar
            };
          })
        };
        addMemberToConversationParams.attributes.member.push({
          user_id: TWILIO_ADMIN_IDENTITY,
          name: 'GTI Admin',
          avatar: ADMIN_AVATAR
        });
      } else {
        throw Error('invalid popup');
      }
      addMemberToConversation(addMemberToConversationParams).catch(() => {});
      this.setState({
        conversation: conversation
      });
    } catch (e) {
      message.error(`Error: ${JSON.stringify(e)}`);
    }
  }
  async createPersonChat(user) {
    // @ts-ignore
    // TODO: FIX THIS
    const uniqueName = String(user?.id * this.state.receiver.identity);
    let conversation;
    conversation = await this.twilioClient.getConversationByUniqueName(uniqueName).catch(() => {
      return null;
    });
    if (!conversation) {
      conversation = await this.twilioClient.createConversation({
        uniqueName
      }).catch(() => {
        return null;
      });
    }
    if (!conversation) {
      throw new Error('Unable to join or create Direct Message conversation');
    }
    await conversation.join().catch(() => {});
    const convAttrs = conversation.attributes;
    addMemberToConversation({
      identity: this.state.receiver.identity,
      conversation_sid: conversation.sid,
      attributes: {
        adminStarChannel: !!convAttrs.adminStarChannel,
        anonymity: !!this.props.request_anonymity,
        member: [{
          user_id: user?.id,
          name: user?.full_name,
          avatar: user?.avatar
        }, {
          user_id: this.state.receiver.identity,
          name: this.state.receiver.full_name,
          avatar: this.state.receiver.avatar
        }]
      }
    });
    this.setState({
      conversation: conversation
    });
  }
  render() {
    // popup is finished loading once charIcon and conversation are set
    return this.state.charIcon && this.state.conversation && <PopUp activeSid={this.state.activeSid} closeChat={this.closeChat.bind(this)} conversation={this.state.conversation} currentRole={this.props.routeRole} charIcon={this.state.charIcon} isAdmin={this.state.myInfor.is_administrator} isDM={this.props.isDM} isHeaderHidden={this.props.hideHeader} isHidden={this.state.hideChat} isOpen={this.state.popupOpen} isStarred={this.state.adminStarChannel} project={this.props.projectInfo} projectId={this.props.project_id} refreshToken={() => this.refreshToken()} suggestedPartners={this.props.suggestedPartners} title={this.props.popupName} toggleChat={this.toggleChat.bind(this)} toggleStarred={starred => this.handleFavoriteChannel(starred)} twilioClient={this.twilioClient} user={this.state.myInfor} />;
  }
}
ChatPopup.contextType = ChatContext;
export default ChatPopup;